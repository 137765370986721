<template>
    <div class="inner-page bill-detail-page" v-if="visible">
        <!-- <div class="steps">
            <div :class="['step-1 step', { active: page.order_time }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="text t1">买家下单</p>
                    <p class="circle">1</p>
                    <p class="text" v-if="page.order_time">
                        {{ page.order_time }}
                    </p>
                </div>
            </div>
            <div :class="['step-1 step', { active: page.pay_time }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="text t1">买家付款</p>
                    <p class="circle">2</p>
                    <p class="text" v-if="page.pay_time">{{ page.pay_time }}</p>
                </div>
            </div>
            <div :class="['step-1 step', { active: page.post_time }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="text t1">卖家发货</p>
                    <p class="circle">3</p>
                    <p class="text" v-if="page.post_time">
                        {{ page.post_time }}
                    </p>
                </div>
            </div>
            <div :class="['step-1 step', { active: page.recieve_time }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="text t1">确认收货</p>
                    <p class="circle">4</p>
                    <p class="text" v-if="page.recieve_time">
                        {{ page.recieve_time }}
                    </p>
                </div>
            </div>
        </div> -->
        <div class="bill-detail-container">
            <div class="bill-info bill-detail-item">
                <p class="title">订单信息</p>
                <p>订单编号: {{ page.tranId }}</p>
                <p>订单类型: {{ orderType[page.orderType] }}</p>
                <p>订单来源: {{ tranSource[page.tradscen] }}</p>
                <p>付款金额: ￥{{ page.payMoney.toFixed(2) }}</p>
                <!-- <p>付款方式: {{ page.pay_type }}</p> -->
                <p>会员信息: {{ page.memName }}({{ page.memPhone }})</p>
                <!-- <p class="liner"></p> -->
            </div>
            <div class="bill-msg-container bill-detail-item">
                <!-- <p class="bill-warning title">
                    如果卖家未在规定时间内支付，订单将按照设置自动关闭
                </p>
                <p class="liner"></p>
                <p>卖家备注: {{ page.seller_msg }}</p>
                <p>
                    温馨提示: 改备注仅作为商家对订单的标记, 不会被卖家查看到。
                </p>
                <p class="liner"></p>
                <p>
                    <span>友情提醒: </span
                    >请务必等待订单状态为“买家已付款，等待卖家发货”后在进行发货。
                </p> -->
                <div>
                    配送方式: {{ deliverType[page.deliveryType] || "无" }}
                </div>
                <div style="display: flex; align-items: baseline">
                    <span style="margin-right: 5px">收货信息: </span>
                    <div
                        v-if="page.address"
                        style="width: 0; flex: 1; word-break: break-all"
                    >
                        <p>
                            {{
                                (page.address.linkName || "") +
                                "  " +
                                (page.address.linkPhone || "") +
                                "   "
                            }}
                        </p>
                        <p>
                            {{
                                (page.address.province || "") +
                                (page.address.city || "") +
                                (page.address.county || "") +
                                (page.address.addDetail || "")
                            }}
                        </p>
                    </div>
                </div>
                <p>买家留言: {{ page.buyer_msg || "无" }}</p>
                <p>发票状态: {{ invoiceSta[page.invoiceSta] || "无需开票" }}</p>
                <p class="back" @click="back">返回</p>
            </div>
        </div>
        <div class="bill-product-list">
            <table>
                <thead style="background-color: #f2f2f2">
                    <tr>
                        <th>商品</th>
                        <th>价格(元)</th>
                        <th>数量</th>
                        <th>满减金额(元)</th>
                        <th>会员折扣(元)</th>
                        <th>优惠券(元)</th>
                        <th>积分抵扣(元)</th>
                        <!-- <th>商家调价(元)</th> -->
                        <th>小计(元)</th>
                        <th>物流信息</th>
                        <!-- <th>单号</th> -->
                    </tr>
                </thead>
                <tbody style="background-color: #fff">
                    <tr v-for="(pru, index) in page.orderShopDtoList" :key="pru.tranId + index">
                        <td>
                            <div class="content">
                                <img :src="pru.iconImg" />
                                <span>{{ pru.pluName }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span v-if="pru.slPrc"
                                    >￥{{ pru.slPrc.toFixed(2) }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span v-if="pru.qty">{{ pru.qty }}件</span>
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span
                                    >￥{{
                                        (pru.mjDisAmt || 0).toFixed(2)
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span
                                    >￥{{
                                        (pru.vipDisAmt || 0).toFixed(2)
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span
                                    >￥{{
                                        (pru.yqAmt || 0).toFixed(2)
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span
                                    >￥{{
                                        (pru.deductPointAmt || 0).toFixed(2)
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <span
                                    >￥{{
                                        (
                                            pru.slPrc * pru.qty -
                                            (pru.mjDisAmt || 0) -
                                            (pru.vipDisAmt || 0) -
                                            (pru.yqAmt || 0) - 
                                            (pru.deductPointAmt || 0)
                                        ).toFixed(2) || "0.00"
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="content">
                                <template v-if="pru.logisticShopDto">
                                    <template
                                        v-if="
                                            pru.logisticShopDto.deliveryType ==
                                            1
                                        "
                                        >自提码
                                        {{
                                            pru.logisticShopDto.pickCode
                                        }}</template
                                    >
                                    <template v-else>
                                        {{ pru.logisticShopDto.expComName }}
                                        {{ pru.logisticShopDto.logisticNo }}
                                    </template>
                                </template>
                            </div>
                        </td>
                        <!-- <td>
                            <div class="content">{{ pru.tranId }}</div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bill-total-tip">
            <p v-if="page.amt">
                商品价格: <span>￥{{ page.amt.toFixed(2) }}</span>
            </p>
            <p>
                快递费用:
                <span>￥{{ page.expFee || "0.00" }}</span>
            </p>
            <p>
                优惠券:
                <span>-￥{{ page.yqAmt || "0.00" }}</span>
            </p>
            <p>
                满减:
                <span>-￥{{ page.mjDisAmt || "0.00" }}</span>
            </p>
            <p>
                会员折扣:
                <span>-￥{{ page.vipDisAmt || "0.00" }}</span>
            </p>
            <p>
                积分抵扣:
                <span>-￥{{ (page.allDeductPointAmt || 0).toFixed(2) }}</span>
            </p>
            <p>
                订单共计{{ page.qty }}件商品，实际支付:
                <span class="error" v-if="page.payMoney"
                    >￥{{ page.payMoney.toFixed(2) }}</span
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderType: {
                1: "普通订单",
                2: "拼团订单",
                3: "秒杀订单",
                4: "积分兑换订单",
                5: "邀请有礼赠送商品订单",
                6: "抽奖订单",
                7: "拼团订单",
                8: "砍价订单"
            },
            tranSource: {
                ["1"]: "专柜收银",
                ["2"]: "集中收银",
                ["3"]: "后台补录",
                ["4"]: "线上商城"
            },
            deliverType: {
                1: "店铺自提",
                2: "快递配送"
            },
            invoiceSta: {
                PENDING: "待开具",
                ISSUING: "开具中",
                ISSUED: "已开具",
                REVERSING: "红冲中",
                REVERSED: "已红冲",
                CLOSED: "已关闭"
            },
            step: 1
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: () => false
        },
        page: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    methods: {
        back() {
            console.log("aaa")
            this.$emit("update:visible", false);
        }
    }
};
</script>

<style lang="scss">
.inner-page.bill-detail-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #eee;
    overflow-y: auto;
    z-index: 10;
    padding: 0 5px 5px;
    &::-webkit-scrollbar {
        width: 0;
    }
    .steps {
        display: flex;
        justify-content: center;
        padding: 60px;
        padding-top: 80px;
        margin-bottom: 20px;
        background: #fff;
        .step {
            width: 25%;
            position: relative;
            .line {
                height: 6px;
                width: 100%;
                background: #ccc;
            }
            .step-intro {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -44px);
                text-align: center;
                width: 100%;
                .circle {
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    background: #ccc;
                    margin: auto;
                }
                .text {
                    margin-top: 10px;
                    &.t1 {
                        margin-top: 0;
                        margin-bottom: 10px;
                    }
                }
            }
            &.active {
                .line,
                .step-intro .circle {
                    color: #fff;
                    background: rgb(45, 140, 240);
                }
            }
        }
    }
    .bill-detail-container {
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        .bill-detail-item {
            background: #f2f2f2;
            padding: 15px;
            &.bill-info {
                // width: 25%;
                flex: 1;
                margin-right: 15px;
            }
            &.bill-msg-container {
                flex: 1;
                position: relative;
                padding-right: 80px;
                .back {
                    position: absolute;
                    top: 30px;
                    right: 20px;
                    height: 28px;
                    line-height: 28px;
                    background: rgb(45, 140, 240);
                    color: #fff;
                    padding: 0 10px;
                    font-size: 14px;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
            p {
                margin: 10px 0;
                // font-size: 14px;
                &.liner {
                    height: 1px;
                    background: #e6e6e6;
                    margin: 10px 0;
                }
                &.title {
                    margin: 20px 16px;
                    font-size: 24px;
                }
            }
        }
    }
    .bill-product-list {
        img {
            width: 80px;
            margin-right: 20px;
        }
        .content {
            background: #fff;
            padding: 20px 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
        }
        table {
            width: 100%;
        }
        th {
            padding: 8px;
            line-height: 1.43;
            font-size: 12px;
        }
        td {
            display: table-cell;
            vertical-align: middle;
            border-top: 1px solid #f4f4f4;
        }
    }
    .bill-total-tip {
        background: #fff;
        padding: 20px;
        margin-top: 20px;
        // font-size: 14px;
        line-height: 28px;
        text-align: right;
    }
}
</style>

<template>
    <div class="refund-detail-panel" v-if="show">
        <i class="iconfont icon-chuyidong1 icon-close-page" @click="cancel"></i>
        <div class="page-title">售后单详情</div>
        <div class="detail-content">
            <el-scrollbar style="height: 100%">
                <div class="status">当前售后状态：
                    <template v-if="detailInfo.sta == '1' && detailInfo.verifyDto">{{staIdx1[detailInfo.verifyDto.sta]}}</template>
                    <template v-else>{{detailInfo.sta == "6" ? (detailInfo.payRefundSta == 1 ? "退款中" : "已退款") : staIdx[detailInfo.sta]}}</template>
                </div>
                <div class="top-container">
                    <div class="apply-info-panel inner-panel">
                        <div class="title">申请信息</div>
                        <div class="info-panel">
                            <div class="cell">
                                <p class="label">售后单号：</p>
                                <p class="value">{{ detailInfo.refundId }}</p>
                            </div>
                            <div class="cell">
                                <p class="label">订单编号：</p>
                                <p class="value">
                                    {{ detailInfo.tranId
                                    }}
                                </p>
                            </div>
                            <div class="cell">
                                <p class="label">售后类型：</p>
                                <!--<p class="value">{{ detailInfo.refundType == '1' ? "整单退（退款）" : "单品退（售后退货）" }}</p>-->
                                <p class="value">退款</p>
                            </div>
                            <div class="cell" v-if="detailInfo.applyDate">
                                <p class="label">申请时间：</p>
                                <p class="value">{{ detailInfo.applyDate }}</p>
                            </div>
                            <div class="cell" v-if="detailInfo.verifyDate">
                                <p class="label">审核时间：</p>
                                <p class="value">{{ detailInfo.verifyDate }}</p>
                            </div>
                            <div class="cell" v-if="detailInfo.refundDate">
                                <p class="label">退款时间：</p>
                                <p class="value">{{ detailInfo.refundDate }}</p>
                            </div>
                            <div class="cell">
                                <p class="label">会员信息：</p>
                                <p class="value">
                                    <template v-if="detailInfo.memName">{{detailInfo.memName}}</template>
                                    <template v-if="detailInfo.memPhone">({{detailInfo.memPhone}})</template>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="apply-reason-panel inner-panel">
                        <div class="title">申请原因</div>
                        <div class="info-panel">
                            <div class="cell">
                                <p class="label">申请原因：</p>
                                <p class="value">{{ detailInfo.reason }}</p>
                            </div>
                            <div class="cell height-cell">
                                <p class="label" style="width: 100px;">
                                    问题&备注：
                                </p>
                                <p class="value" style="line-height:18px;padding-top:6px;">{{ detailInfo.applyDesc }}</p>
                            </div>
                            <div class="cell height-cell">
                                <p class="label">反馈图片：</p>
                                <div class="value" style="line-height: 30px;">
                                    <template v-if="!imgs || imgs.length === 0"
                                        >用户未上传图片</template
                                    >
                                    <template v-else>
                                        <el-image
                                            v-for="img in imgs"
                                            :key="img"
                                            :src="img"
                                            :preview-src-list="[img]"
                                        />
                                    </template>
                                </div>
                            </div>
                            <div class="cell" v-if="detailInfo.rejectReason">
                                <p class="label">审核回复：</p>
                                <p class="value">{{ detailInfo.rejectReason }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="refund-plu-list" style="margin-top:20px;">
                    <table border="1" style="border-collapse:collapse;">
                        <thead style="background-color: #f2f2f2">
                            <tr>
                                <th>退回商品</th>
                                <th>规格</th>
                                <th>售价(元)</th>
                                <th>退回数量(件)</th>
                                <th>商品金额(元)</th>
                                <th>付款金额(元)</th>
                                <th>退款金额(元)</th>
                                <!-- <th>物流信息</th> -->
                            </tr>
                        </thead>
                        <tbody style="background-color: #fff">
                            <tr v-for="pru in detailInfo.refundGoodsList" :key="pru.tranId">
                                <td>
                                    <div class="content">
                                        <img :src="pru.iconImg" />
                                        <span>{{ pru.pluName }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="content" v-html="formatSpec(pru.skuDetail)"></div>
                                </td>
                                <td>
                                    <div class="content">
                                        <span v-if="pru.slPrc"
                                            >￥{{ pru.slPrc.toFixed(2) }}</span
                                        >
                                    </div>
                                </td>
                                <td>
                                    <div class="content">
                                        <span v-if="pru.qty">{{ pru.qty }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="content">
                                        <span v-if="pru.qty">{{ pru.amt.toFixed(2) }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="content">
                                        <span v-if="pru.qty">{{ pru.payMoney.toFixed(2) }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="content">
                                        <span v-if="pru.qty">{{ pru.refundMoney.toFixed(2) }}</span>
                                    </div>
                                </td>
                                <!-- <td>
                                    <div class="content">
                                        <template v-if="pru.logisticShopDto">
                                            <template
                                                v-if="
                                                    pru.logisticShopDto.deliveryType ==
                                                    1
                                                "
                                                >自提码
                                                {{
                                                    pru.logisticShopDto.pickCode
                                                }}</template
                                            >
                                            <template v-else>
                                                {{ pru.logisticShopDto.expComName }}
                                                {{ pru.logisticShopDto.logisticNo }}
                                            </template>
                                        </template>
                                    </div>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bill-total-tip">
                    <p v-if="detailInfo.amt">
                        商品价格: <span>￥{{ detailInfo.amt.toFixed(2) }}</span>
                    </p>
                    <p>
                        优惠券:
                        <span>-￥{{ detailInfo.yqAmt || "0.00" }}</span>
                    </p>
                    <p>
                        满减:
                        <span>-￥{{ detailInfo.mjDisAmt || "0.00" }}</span>
                    </p>
                    <p>
                        会员折扣:
                        <span>-￥{{ detailInfo.vipDisAmt || "0.00" }}</span>
                    </p>
                    <p>
                        订单共计{{ qty }}件商品，退款金额:
                        <span class="error" v-if="detailInfo.payMoney"
                            >￥{{ detailInfo.payMoney.toFixed(2) }}</span
                        >
                    </p>
                </div>
            </el-scrollbar>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return { 
            staIdx: {
                1: "待审核",
                2: "审核通过-待发货",
                3: "审核不通过",
                4: "客户已发货-待收货",
                5: "已收货-待退款",
                6: "退款成功（已完成）",
                8: "已取消"
            },
            
            staIdx1: { //待审核单据展示信息
                1: "待审核",
                2: "审核通过",
                3: "审核不通过"
            },
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        detailInfo: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        qty() {
            let num = 0;
            this.detailInfo.refundGoodsList.map(it => {
                num += it.qty;
            })
            return num;
        },
        imgs() {
            return this.detailInfo.descImg ? this.detailInfo.descImg.split(";") : []
        }
    },
    methods: {
        formatSpec(spec) {
            return spec.replace(/[\[\]"{}]/g, "").split(",").join(" <br> ");
        },
        cancel() {
            this.$emit("update:show", false);
        }
    }
};
</script>

<style lang="scss">
.refund-detail-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    .bill-total-tip {
        background: #fff;
        padding: 20px;
        margin-top: 20px;
        // font-size: 14px;
        line-height: 28px;
        text-align: right;
    }
    .icon-close-page {
        position: absolute;
        top: 12px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
    }
    .page-title {
        height: 48px;
        line-height: 48px;
        padding: 0 20px;
        font-size: 18px;
        border-bottom: 1px solid #999;
    }
    .detail-content {
        height: calc(100% - 50px);
        overflow-y: auto;
        .el-scrollbar__wrap {
            overflow-x: hidden;
            padding: 15px;
        }
        .status {
            background-color: #efefef;
            height: 42px;
            line-height: 42px;
            padding: 0 15px;
        }
        .top-container {
            display: flex;
            border: 1px solid #cfcfcf;
            margin-top: 20px;
            .inner-panel {
                padding: 20px;
                flex: 1;
                & + .inner-panel {
                    border-left: 1px solid #cfcfcf;
                }
                .title {
                    line-height: 48px;
                    font-size: 20px;
                }
                .info-panel {
                    padding-left: 30px;
                    .cell {
                        display: flex;
                        min-height: 30px;
                        &.height-cell {
                            height: auto;
                            align-items: flex-start;
                        }
                        .label {
                            width: 100px;
                            margin-right: 10px;
                            text-align: justify;
                            height: 30px;
                            line-height: 30px;
                            &::after {
                                content: "";
                                display: inline-block;
                                height: 0;
                                width: 100%;
                            }
                        }
                        .value {
                            width: 0;
                            flex: 1;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            word-break: break-all;
                            .el-image {
                                width: 80px;
                                height: 80px;
                                margin: 10px;
                            }
                        }
                    }
                }
            }
        }
        .refund-plu-list {
            img {
                width: 80px;
                margin-right: 20px;
            }
            .content {
                justify-content: center;
                background: #fff;
                padding: 20px 10px;
                display: flex;
                align-items: center;
                font-size: 14px;
            }
            table {
                width: 100%;
            }
            th {
                padding: 8px;
                line-height: 1.43;
                font-size: 12px;
            }
            tr {
                border: 1px solid #f4f4f4;
                & + tr {
                    border-top: none;
                }
            }
            td {
                display: table-cell;
                vertical-align: middle;
                // border-right: 1px solid #f4f4f4;
                & + td {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}
</style>
<template>
    <div class="page aftermarket-manage-page">
        <Search
            :items="searchItems"
            :data.sync="searchData"
            @search="startSearch"
            @reset="reset"
        />
        <!-- <el-radio-group
            v-model="sta"
            @change="startSearch"
            class="table-tab-container"
        >
            <el-radio-button
                v-for="tab in tableTabs"
                :key="tab.value"
                :label="tab.value"
                >{{ tab.label }}</el-radio-button
            >
        </el-radio-group> -->
        <div class="table-tab-panel">
            <el-radio-group v-model="sta" size="small" @change="startSearch">
                <el-radio-button
                    v-for="tab in tableTabs"
                    :key="tab.value"
                    :label="tab.value"
                >{{ tab.label }}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="aftermarket-table-container">
            <div class="bill-table-head">
                <div style="width: 20%">商品信息</div>
                <div style="width: 10%">价格（元）及数量</div>
                <div style="width: 10%">会员信息</div>
                <div style="width: 10%">订单金额（元）</div>
                <div style="width: 10%">实付金额（元）</div>
                <div style="width: 10%">本次退款金额</div>
                <div style="width: 10%">订单状态</div>
                <div style="width: 20%">操作</div>
            </div>
            <div class="bill-table-body">
                <div
                    v-if="list.length == 0"
                    style="padding: 20px; text-align: center"
                    class="empty"
                >
                    暂无数据{{list.length}}
                </div>
                <el-scrollbar v-else style="height: 100%">
                    <div
                        class="bill-list-item"
                        v-for="it in list"
                        :key="it.tranId + it.refundId"
                    >
                        <div class="bill-item-head">
                            <div class="left-head">
                                <p>售后订单号: {{ it.refundId }}</p>
                                <p>原订单号: {{ it.tranId }}</p>
                                <p>申请时间: {{it.applyDate}}</p>
                            </div>
                            <div class="right-head">
                                <p class="blue-link" @click="orderDetail(it)">
                                    订单详情
                                </p>
                                <!-- <p class="blue-link" @click="orderRemark(it)">
                                    订单备注
                                </p> -->
                            </div>
                        </div>
                        <div class="bill-item-detail">
                            <div class="picture" style="width: 30%">
                                <div
                                    class="order-item-info"
                                    v-for="item in it.orderShopDtoList"
                                    :key="item.tranPluId"
                                >
                                    <div class="order-item-name-spec">
                                        <img :src="item.iconImg" />
                                        <div>
                                            <p class="order-item-name">
                                                {{ item.pluName }}
                                            </p>
                                            <p
                                                class="order-item-spec"
                                                v-html="
                                                    formatSpec(item.skuDetail)
                                                "
                                            ></p>
                                        </div>
                                    </div>
                                    <div class="order-item-num-price">
                                        <p class="order-item-price">
                                            ￥{{ item.slPrc.toFixed(2) }}
                                        </p>
                                        <p class="order-item-price">
                                            {{ item.qty }}件
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="buyer-info table-item"
                                style="width: 10%"
                            >
                                <p class="buyer-name">
                                    {{ it.memName }}
                                </p>
                                <p class="buyer-phone">
                                    {{ it.memPhone }}
                                </p>
                            </div>
                            <!-- <div
                                class="post-type table-item"
                                style="width: 10%"
                            >
                                {{ deliveryType[it.deliveryType] }}
                            </div> -->
                            <div
                                class="order-money table-item"
                                style="width: 10%"
                            >
                                ￥{{ it.amt.toFixed(2) }}
                            </div>
                            <div
                                class="truly-money table-item"
                                style="width: 10%"
                            >
                                ￥{{ it.payMoney.toFixed(2) }}
                            </div>
                            <div
                                class="order-status table-item"
                                style="width: 10%"
                            >
                                ￥{{ it.refundMoney.toFixed(2) }}
                            </div>
                            <div
                                class="order-status table-item"
                                style="width: 10%"
                            >
                                <!-- <template v-if="it.sta == '1'" && it.verifyDto>
                                    {{staIdx1[it.verifyDto.sta]}}
                                </template>
                                <template v-else>{{ staIdx[it.sta] }}</template>     -->
                                <template>{{ it.sta == "6" ? (it.payRefundSta == 1 ? "退款中" : "已退款") : staIdx[it.sta] }}</template>    
                            </div>
                            <div
                                class="order-opt table-item btn-group"
                                style="width: 20%"
                            >
                                <p
                                        class="btn"
                                        v-if="it.sta === '1'"
                                        @click="verify(it)"
                                    >
                                        审核
                                    </p>
                                    <!-- <p
                                        class="btn"
                                        v-if="it.sta === '4'"
                                        @click="receive(it)"
                                    >
                                        确认收货
                                    </p> -->
                                    <p
                                        class="btn"
                                        v-if="it.sta === '2'"
                                        @click="refund(it)"
                                    >
                                        去退款
                                    </p>
                                    <p class="btn" @click="detail(it)">售后详情</p>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50]"
                :page-size.sync="pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="total"
                :hide-on-single-page="true"
            ></el-pagination>
        </div>
        <detail :show.sync="showDetail" :detailInfo="detailInfo" />
        <order-detail :visible.sync="orderDetailVisiblevisi" :page="pageData" />
        <el-dialog
            :visible.sync="showLog"
            :close-on-click-modal="false"
            :title="title"
            @close="closeLog"
        >
            <div class="dialog-panel">
                <template v-if="mode === 'verify'">
                    <div class="cell">
                        <div class="label">选择处理方式:</div>
                        <div class="content">
                            <el-radio v-model="radio" label="1"
                                >同意申请通过</el-radio
                            >
                            <el-radio v-model="radio" label="2"
                                >拒绝申请通过</el-radio
                            >
                        </div>
                    </div>
                    <div class="cell height-cell">
                        <div class="label">审核留言:</div>
                        <div class="content">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 4 }"
                                v-model="textarea"
                                show-word-limit
                                maxlength="50"
                            />
                            <div
                                style="
                                    font-size: 12px;
                                    color: #acacac;
                                    margin-top: 4px;
                                "
                            >
                                向用户说明返回原因或备注, 用户可在申请单详情看到
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="mode === 'receive'">
                    <div class="cell color-cell">
                        *请您确认退回商品并设置将要退回给用户的金额，确认后将生成退款单，可在退款申请管理查看
                    </div>
                    <div class="cell">
                        <div class="label">退回微信:</div>
                        <div class="content">
                            <el-input class="amt-input" v-model="wxPay" />元
                            <span
                                style="
                                    font-size: 12px;
                                    color: #acacac;
                                    padding-left: 10px;
                                "
                                >退回微信支付金额不得大于订单的实付微信支付的金额</span
                            >
                        </div>
                    </div>
                    <div class="cell">
                        <div class="label">退回余额:</div>
                        <div class="content">
                            <el-input
                                class="amt-input"
                                v-model="accountPay"
                            />元
                        </div>
                    </div>
                    <div class="cell">
                        <div class="label">退回积分:</div>
                        <div class="content">
                            <el-input class="amt-input" v-model="pointPay" />元
                        </div>
                    </div>
                    <div
                        style="font-size: 12px; color: #acacac; margin-top: 4px"
                    >
                        以上是经系统计算后，建议的退款金额，可修改
                    </div>
                </template>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="confirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import data from "./data";
import { RESET_SEARCH } from "../../events/eventBus";
import { Base64 } from "../../../../utils/base64.min.js";
import detail from "./detail.vue";
import orderDetail from "../bill/detail.vue";
export default {
    mixins: [data],
    data() {
        return {
            Base64: Base64,
            sta: 0,
            searchData: {},
            pageSize: 10,
            pageIndex: 1,
            total: 0,
            list: [],
            loading: false,
            showLog: false,
            showDetail: false,
            detailInfo: {},
            title: "",
            mode: "",
            radio: "1",
            textarea: "",
            wxPay: 0,
            accountPay: 0,
            pointPay: 0,
            target: {},
            pageData: {},
            orderDetailVisiblevisi: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
            this.$EventBus.$on(RESET_SEARCH, () => {
                this.searchData = {};
                this.sta = 0;
                // this.startSearch();
            });
        });
    },
    components: {
        detail, orderDetail
    },
    methods: {
        formatSpec(spec) {
            return spec.replace(/[\[\]"{}]/g, "").split(",").join(" <br> ");
        },
        orderDetail(item) {
            this.$post("/shop/api/order/detail", { tranId: item.tranId }).then(
                data => {
                    this.pageData = data;
                    this.orderDetailVisiblevisi = true;
                }
            );
        },
        startSearch() {
            this.pageIndex = 1;
            this.searchData.sta = this.sta;
            if (this.sta === 0) delete this.searchData.sta;
            this.getData();
        },
        reset() {},
        verify(item) {
            this.showLog = true;
            this.title = "审核";
            this.mode = "verify";
            this.target = item;
        },
        receive(item) {
            this.$confirm('确定收货', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post("/shop/api/refund/confirmReceipt", {
                    refundId: item.refundId
                }).then(data => {
                    this.$message({
                        type: 'success',
                        message: '成功收货!'
                    });
                    this.getData();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消收货'
                });          
            });
        },
        refund(item) {
            this.$confirm('确定退款', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post("/shop/api/refund/confirmRefund", {
                    refundId: item.refundId
                }).then(data => {
                    this.$message({
                        type: 'success',
                        message: '成功退款!'
                    });
                    this.getData();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消退款'
                });          
            });
        },
        detail(item) {
            this.$post("/shop/api/refund/getOrderDetail", {refundId: item.refundId}).then(data => {
                this.detailInfo = data;
                this.showDetail = true;
            })
        },
        closeLog() {
            this.showLog = false;
            this.title = "";
            this.radio = "1";
            this.textarea = "";
            this.wxPay = 0;
            this.accountPay = 0;
            this.pointPay = 0;
            this.target = {};
        },
        confirm() {
            switch(this.mode) {
                case "verify": this.verifySure();break;
                case "receive": this.receiveSure();break;
                default: break;
            }
        },
        verifySure() {
            if (this.radio == 2 && !this.textarea) {
                this.$message({
                    type: 'info',
                    message: '请填写审核留言'
                });
                return;
            }
            this.$post("/shop/api/refund/verify", {
                refundId: this.target.refundId,
                verifySta: this.radio,
                description: this.textarea
            }).then(data => {
                this.$message.success("审核成功");
                this.getData();
                this.closeLog();
            })
        },
        receiveSure() {
            this.$post("/shop/api/refund/confirmReceipt", {
                refundId: this.target.refundId
            }).then(data => {
                this.closeLog();
            })
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.getData();
        },
        handleCurrentChange(curr) {
            this.pageIndex = curr;
            this.getData();
        },
        getData() {
            this.loading = true;
            if (this.searchData.date && !this.searchData.date[0]) {
                delete this.searchData.date;
            }
            this.searchData.refundType = 1;
            this.$post("/shop/api/refund/getOrderList", Object.assign({}, {pageSize: this.pageSize, pageIndex: this.pageIndex}, this.searchData)).then(data => {
                this.list = data.datas;
                this.total = data.recordCount;
                this.loading = false;
            })
            
        }
    }
};
</script>

<style lang="scss">
.page.aftermarket-manage-page {
    display: flex;
    flex-direction: column;
    position: relative;
    .table-tab-container {
        background-color: #fff;
        padding-left: 20px;
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            box-shadow: none;
        }
        .el-radio-button {
            .el-radio-button__inner {
                background: #eee;
                border-bottom: none;
            }

            &.is-active {
                .el-radio-button__inner {
                    background: #fff;
                    border: 1px solid #ccc;
                    border-bottom: none;
                    color: #000;
                }
            }
        }
    }
    .aftermarket-table-container {
        background-color: #fff;
        padding: 0 20px 20px;
        flex: 1;
        overflow: hidden;

        .bill-table-head {
        display: flex;
        background: #f8f8f8;
        font-size: 12px;
        height: 50px;
        line-height: 50px;
        div {
            text-align: center;
        }
    }
    .bill-table-body {
        height: calc(100% - 50px);
        overflow: auto;
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .bill-list-item {
            margin-top: 20px;
            &:hover {
                background: #f5f7fa;
            }
            .bill-item-head {
                display: flex;
                justify-content: space-between;
                background: #eee;
                padding: 10px;
                font-size: 12px;
                .left-head {
                    display: flex;
                    p {
                        margin-right: 20px;
                    }
                }
                .right-head {
                    display: flex;
                    color: #2d8cf0;
                    cursor: pointer;
                    .blue-link {
                        margin: 0 20px;
                        &:hover {
                            color: #777;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .bill-item-detail {
                display: flex;
                font-size: 12px;
                .picture {
                    border-left: 1px solid #eee;
                    .order-item-info {
                        display: flex;
                        border-bottom: 1px solid #eee;
                        .order-item-name-spec {
                            padding: 10px;
                            display: flex;
                            flex: 1;
                            border-right: 1px solid #eee;
                            img {
                                width: 80px;
                                height: 80px;
                                margin-right: 10px;
                            }
                            p {
                                margin-bottom: 14px;
                                &.order-item-spec {
                                    color: #aaa;
                                }
                            }
                        }
                        .order-item-num-price {
                            width: 25%;
                            padding: 10px;
                            border-right: 1px solid #eee;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            p {
                                margin: 6px;
                            }
                        }
                    }
                }
                .btn-group {
                    display: flex;
                    justify-content: center;
                    .btn {
                        text-align: center;
                        line-height: 24px;
                        padding: 0 6px;
                        color: #fff;
                        background-color: #2d8cf0;
                        border-radius: 4px;
                        cursor: pointer;
                        & + .btn {
                            margin-left: 10px;
                        }
                    }
                }
                .table-item {
                    display: flex;
                    padding: 10px;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #eee;
                    border-bottom: 1px solid #eee;
                    &.buyer-info {
                        width: 25%;
                        padding: 10px;
                        border-right: 1px solid #eee;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        p {
                            margin: 6px;
                        }
                    }
                    .cell-btn-con {
                        position: relative;
                        background: rgb(45, 140, 240);
                        line-height: 24px;
                        border-radius: 4px;
                        cursor: pointer;
                        padding: 0 6px;
                        color: #fff;
                    }
                    .icon-wuliu {
                        width: 24px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        color: #fff;
                        border-radius: 4px;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    }
    .pagination {
        background: #fff;
        .el-pagination {
            padding: 10px 20px;
        }
    }
    .el-dialog {
        padding: 15px;
        .el-dialog__header {
            padding: 0 0 15px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .el-dialog__headerbtn {
                top: 28px;
                right: 30px;
            }
            .el-dialog__title {
                color: #333;
                font-size: 20px;
                line-height: 28px;
            }
        }
        .el-dialog__body {
            padding: 0;
        }
        .el-dialog__footer {
            padding: 30px 0 0;
            text-align: center;
            .el-button--primary {
                width: 160px;
            }
        }
        .dialog-panel {
            .cell {
                display: flex;
                height: 42px;
                align-items: center;
                .label {
                    width: 100px;
                    text-align: justify;
                    margin-right: 10px;
                    font-size: 14px;
                    height: 42px;
                    line-height: 42px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 0;
                    }
                }
                .content {
                    width: 0;
                    flex: 1;
                    line-height: 42px;
                    .amt-input {
                        width: 120px;
                        margin-right: 8px;
                    }
                }
                &.height-cell {
                    height: auto;
                    align-items: flex-start;
                }
                &.color-cell {
                    color: red;
                }
            }
        }
    }
}
</style>
export default {
    data() {
        return {
            searchItems: [
                {
                    label: "售后单编号",
                    key: "refundId"
                }, {
                    label: "订单编号",
                    key: "tranId"
                },  {
                    label: "联系人",
                    key: "memName"
                }, {
                    label: "联系方式",
                    key: "memPhone"
                }, {
                    label: "申请时间段",
                    type: "date",
                    dateType: "datetimerange",
                    key: "date",
                    keys: ["fsDateFrom", "fsDateTo"],
                    range: true
                }
            ],
            tableTabs: [
                { label: "全部", value: 0 },
                { label: "待审核", value: 1 },
                { label: "审核通过", value: 2 },
                { label: "审核不通过", value: 3 },
                { label: "退款成功", value: 6 },
                { label: "已取消", value: 8 }
            ],
            staIdx: {
                1: "待审核",
                2: "审核通过",
                3: "审核不通过",
                6: "退款成功",
                8: "已取消"
            },
            staIdx1: { //待审核单据展示信息
                1: "待审核",
                2: "审核通过",
                3: "审核不通过"
            },
            tableColumns: [
                { label: "售后单号", key: "refundId", width: "10%" },
                { label: "关联订单号", key: "tranId", width: "10%" },
                { label: "联系人", key: "memName", width: "10%" },
                { label: "联系方式", key: "memPhone", width: "10%" },
                { label: "申请时间", key: "applyDate", width: "20%" },
                // { label: "售后单类型", key: "refundType", width: "10%" },
                { label: "售后单状态", key: "sta", width: "20%" },
                { label: "操作", width: "20%" },
            ]
        }
    }
}